(function(angular){
  'use strict';

  angular.module('errors.directives', [])

    .directive('serverError', function(){
      return {
        restrict: 'A',
        replace: true,
        scope: {
          serverRequest: '=serverError'
        },
        templateUrl: 'app/common/directives/errors/server_error.tpl.html'
      };
    })

    .directive('fieldError', function(){
      return {
        restrict: 'A',
        replace: true,
        scope: {
          formObj: '=fieldError',
          modelAttr: '@',
          form: '=',
          inputName: '@'
        },
        templateUrl: 'app/common/directives/errors/field_error.tpl.html'
      };
    });
}(window.angular));

(function(angular){
  'use strict';

  angular.module('request.services', [])

    .factory('ServerError', function(){
      var ServerError = function(formFields){
        this.formFields = formFields;
        this.reset();
      };

      ServerError.introError = 'Lo sentimos!';
      ServerError.errorMessages = {
        //'form': 'Hemos encontrado errores en el formulario. Por favor verifíquelo e intente nuevamente.',
        400: 'Se produjo un error inesperado en la aplicación (400).',
        401: 'Usted no tiene acceso al recurso solicitado.',
        404: 'No se encontró el recurso solicitado.',
        500: 'Se produjo un error inesperado en la aplicación (500).'
      };

      ServerError.prototype.reset = function(){
        this.code = null;
        this.type = null;
        this.message = {};
        this.resetFieldsErrors();
      };

      ServerError.prototype.resetFieldsErrors = function(){
        this.formFields = {};
      };

      ServerError.prototype.setCode = function(code){
        this.code = code;
        //this.type = (this.code === 5) ? 'warning' : 'error';
        this.type = 'error';
      };

      ServerError.prototype.setMessage = function(data, isForm){
        this.message.intro = ServerError.introError;
        this.message.main = ServerError.mainError(this.code, data || {}, this.formFields, isForm);
        this.message.extra = ServerError.extraError(this.code, data || {}, isForm);
      };

      ServerError.prototype.setFieldsErrors = function(fieldErrors){
        // @TODO: los errores de cada campo deberian estar en un array, pero
        // parece que se puede dar el caso de que vegan un un objecto. Habria
        // que convertir ese objeto en array.
        this.formFields = fieldErrors || {};
      };

      ServerError.prototype.getFullMessage = function(){
        var message = _.sprintf('%s %s', this.message.intro, this.message.main);
        if(this.message.extra){
          message += _.sprintf(' %s', this.message.extra);
        }
        return message;
      };

      ServerError.mainError = function(errorCode, data, formFields, isForm){
        var message;
        if(data.message){
          message = data.message;
        }else if(isForm === true){
          var numberErrors = _.size(formFields);
          if(numberErrors > 0){
            if(numberErrors === 1){
              message = 'Hemos encontrado 1 error en el formulario.';
            }else{
              message = _.sprintf('Hemos encontrado %s errores en el formulario.', numberErrors);
            }
            message += ' Por favor verifíquelo e intente nuevamente.';
          }else{
            message = ServerError.errorMessages[errorCode];
          }
        }else{
          message = ServerError.errorMessages[errorCode];
        }
        if(!message){
          // Mensaje por defecto
          message = ServerError.errorMessages[400];
        }
        return message;
      };

      ServerError.extraError = function(errorCode, data, isForm){
        var message;
        if(isForm !== true){
          message = 'Por favor intente en unos minutos y si el problema persiste, consúltelo con el administrador del sistema.';
        }
        return message;
      };

      return ServerError;
    })

    .factory('ServerRequest', ['ServerError', function(ServerError){
      var ServerRequest = function(){
        this.error = new ServerError();
        this.reset();
      };

      ServerRequest.prototype.reset = function(status){
        this.status = status || 'initialized';  // initialized, working, ok, error
        this.error.reset();
      };

      ServerRequest.prototype.start = function(){
        this.reset('working');
      };

      ServerRequest.prototype.success = function(){
        this.status = 'ok';
      };

      ServerRequest.prototype.setErrorResponse = function(response, isForm){
        this.status = 'error';
        this.error.setCode(response.status);

        if(response.data && angular.isObject(response.data.errors)){
          // Setea los errores de campo del formulario
          this.error.setFieldsErrors(response.data.errors);
        }

        // Setea el error de formulario
        this.error.setMessage(response.data, isForm);
      };

      ServerRequest.prototype.setStatus = function(status){
        this.status = 'error';
        this.error.setCode(status);

        // Resetea los errores de campo del formulario
        this.error.resetFieldsErrors();

        // Setea el error de formulario
        this.error.setMessage();
      };

      ServerRequest.prototype.setCustomWarning = function(warningMsg){
        this.status = 'error';
        this.error.setCode(5);

        // Resetea los errores de campo del formulario
        this.error.resetFieldsErrors();

        // Setea el error de formulario
        this.error.setMessage({message: warningMsg});
      };

      ServerRequest.prototype.setCustomError = function(errorMsg){
        this.status = 'error';
        this.error.setCode(4);

        // Resetea los errores de campo del formulario
        this.error.resetFieldsErrors();

        // Setea el error de formulario
        this.error.setMessage({message: errorMsg});
      };

      ServerRequest.prototype.getFullError = function(){
        return this.error.getFullMessage();
      };

      return ServerRequest;
    }]);
}(window.angular));

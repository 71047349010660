(function(angular) {
  'use strict';

  angular.module('infographic', [])

    .config(['$stateProvider', function($stateProvider){
      $stateProvider
        .state('main.views.infographic', {
          url: '/infografia',
          templateUrl: 'app/modules/infographic/infographic.tpl.html'
        });
    }]);
}(window.angular));

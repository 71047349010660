(function(angular){
  'use strict';

  angular.module('about', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.about', {
            url: '/acerca',
            templateUrl: 'app/modules/about/about.tpl.html'
          });
      }
    ]);
}(window.angular));

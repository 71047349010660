(function(angular){
  'use strict';

  angular.module('math.services', [])

    .factory('MathService', function(){
      return {
        round: function(num){
          return Math.round(num * 100) / 100;
        },
        toPrecision: function(number, precision){
          if(number === 0){
            return 0;
          }
          var n =  Math.log(number) / Math.LN10;
          var x = precision - n;
          if(x < 0){
            x = 0;
          }
          number = number.toFixed(x);
          return number;
        }
      };
    });
}(window.angular));

(function(angular){
  'use strict';

  angular.module('images', [])

    .factory('UniteGallery', function(){
      return {
        generateDom: function(galleryId, images){
          var $gallery = $('<div></div>')
            .attr('id', galleryId)
            .css('display', 'none');
          angular.forEach(images, function(image){
            //var $a = $('<a href=""></a>');
            var $img = $('<img />')
            //.attr('src', image.url + '=s350')
              .attr('src', image.url)
              .attr('data-image', image.url)
              .attr('alt', image.creditos)
              .attr('data-description', '')
              .css('display', 'none');
            //$a.append($img);
            //$gallery.append($a);
            $gallery.append($img);
          });
          return $gallery;
        }
      };
    })

    .directive('gallery', ['UniteGallery',
      function(UniteGallery){
        return {
          restrict: 'A',
          replace: false,
          scope: {
            images: '=gallery',
            options: '='
          },
          templateUrl: 'app/common/directives/images/gallery.tpl.html',

          link: function(scope, element, attrs){
            var $galleryOuter = element.find('div.gallery-outer'), $gallery, uniteGallery, flag = true;

            scope.$watch('images', function(newValue, oldValue){
              if(newValue !== oldValue || flag){
                debug.info('gallery $watch images', newValue);

                flag = false;

                if($gallery){
                  $gallery.remove();
                }
                $galleryOuter.empty();
                if(uniteGallery){
                  uniteGallery.destroy();
                }

                if(angular.isArray(newValue) && newValue.length === 0){
                  $galleryOuter.css('display', 'none');
                  $gallery = null;
                }else{
                  $gallery = UniteGallery.generateDom('gallery-' + '123', newValue);
                  $galleryOuter.append($gallery);
                  $galleryOuter.css('display', 'block');
                  uniteGallery = $gallery.unitegallery(scope.options);
                }
              }
            });
          }
        };
      }
    ]);
}(window.angular));
(function(angular){
  'use strict';

  angular.module('documents', [])

    .directive('documents', function(){
      return {
        restrict: 'A',
        replace: true,
        scope: {
          files: '=documents',
          title: '@',
          serverRequest: '='
        },
        templateUrl: 'app/common/directives/documents/main.tpl.html'
      };
    })

    .directive('documentsItem', function(){
      return {
        restrict: 'A',
        scope: {
          item: '=documentsItem'
        },
        templateUrl: 'app/common/directives/documents/item.tpl.html',

        link: function(scope, element, attrs){
          scope.collapsed = true;
        }
      };
    });
}(window.angular));

(function(angular){
  'use strict';

  angular.module('contact', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.contact', {
            url: '/contacto',
            templateUrl: 'app/modules/contact/contact.tpl.html',
            controller: 'Contact.MainController'
          });
      }
    ])

    .controller('Contact.MainController', ['$scope', 'toaster', 'scrollHelper', 'Form', 'Contact',
      function($scope, toaster, scrollHelper, Form, Contact){
        $scope.form = new Form();

        $scope.submit = function(){
          var model = $scope.form.getFinalModel();
          $scope.form.start();
          Contact.send(model, 1).then(function(data){
            $scope.form.resetForm();
            $scope.contactForm.$setPristine();

            var notification = 'Se ha enviado el formulario correctamente';
            toaster.pop('success', '', notification);
          }, function(response){
            $scope.form.setErrorResponse(response);
            scrollHelper.top();
          });
        };

        $scope.form.resetForm(false, 'Formulario de contacto');
        if($scope.contactForm){
          $scope.contactForm.$setPristine();
        }
      }
    ]);
}(window.angular));

(function(angular){
  'use strict';

  angular.module('terms', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.terms', {
            url: '/terminos',
            templateUrl: 'app/modules/terms/main.tpl.html'
          });
      }
    ]);
}(window.angular));

(function(angular){
  'use strict';

  angular.module('topBar', [])

    .controller('TopBar.MainController', ['$scope', '$window', '$modal', '$state', 'toaster', 'Form', 'User', 'runapv1',
      function($scope, $window, $modal, $state, toaster, Form, User, runapv1){
        var modal;

        var initModal = function(){
          modal = $modal({
            show: false,
            backdrop: 'static',
            templateUrl: 'app/modules/top_bar/login_form.tpl.html'
          });

          modal.$scope.form = new Form();

          modal.$scope.submit = function(){
            var model = modal.$scope.form.getFinalModel();
            modal.$scope.form.start();
            User.login(model).then(function(data){
              // Oculta el modal
              modal.$scope.$hide();

              var notification = 'Ha ingresado correctamente a Runap, por favor espere un momento...';
              toaster.pop('success', '', notification);

              // Redirecciona
              $window.location = _.sprintf('%s/%s?sssid=%s', runapv1, data.redirect, data.sid);
            }, function(response){
              modal.$scope.form.setErrorResponse(response);
              // @TODO subir el scroll del modal para ver el mensaje de error
            });
          };

          modal.$scope.close = function(){
            // Oculta el modal
            modal.$scope.$hide();
          };

          modal.$scope.signUp = function(){
            $state.go('main.views.signUp');
            // Oculta el modal
            modal.$scope.$hide();
          };

          modal.$scope.forgotPassword = function(){
            $state.go('main.views.forgotPassword');
            // Oculta el modal
            modal.$scope.$hide();
          };
        };

        $scope.login = function(){
          modal.$scope.form.resetForm(false, 'Ingreso Autoridades Ambientales');

          // Muestra el modal
          modal.$promise.then(function(){
            modal.show();
          });
        };

        initModal();
      }
    ]);
}(window.angular));

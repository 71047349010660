(function(angular, s){
  'use strict';

  angular.module('runap.app', [
    'ngAnimate',
    'ngMessages',
    'ui.router',
    'angular-cache',
    'angular-lodash',
    'ui.bootstrap',
    'mgcrea.ngStrap',
    'duScroll',
    'toaster',

    // Resources and templates
    'config',
    'runap.data',
    'templates',

    // Services
    'app.services',
    'string.services',
    'math.services',
    'request.services',
    'paginator.services',
    'form.services',

    // Filters
    'string.filters',
    'math.filters',

    // Directives
    'app.directives',
    'errors.directives',
    'buttons.directives',
    'images',
    'charts',
    'documents',

    // General modules
    'topBar',
    'megaMenu',
    'home',
    'about',
    'contact',
    'signUp',
    'forgotPassword',

    // Super modules
    'tests',

    // Single modules
    'terms',
    'faq',
    'infographic',
    'summary',
    'area',
    'department',
    'organization',
    'category'
  ])

    .config(['$locationProvider', '$urlRouterProvider', '$stateProvider',
      function($locationProvider, $urlRouterProvider, $stateProvider){
        $locationProvider.html5Mode(true);

        $urlRouterProvider.otherwise('/error404');

        $stateProvider
          .state('main', {
            abstract: true,
            templateUrl: 'app/layouts/main.tpl.html',
            data: {
              pageTitle: '',
              bodyClass: 'page-container-bg-solid page-boxed page-md'
            }
          })
          .state('main.views', {
            resolve: {
              departamentos: ['$q', 'Departamentos', function($q, Departamentos){
                var deferred = $q.defer();
                Departamentos.query().then(function(data){
                  deferred.resolve(data.items);
                }, function(response){
                  deferred.reject(response);
                });
                return deferred.promise;
              }],
              organizaciones: ['$q', 'Organizaciones', function($q, Organizaciones){
                var deferred = $q.defer();
                var params = {categoria: 'CAR'};
                Organizaciones.query(params).then(function(data){
                  deferred.resolve(data.items);
                }, function(response){
                  deferred.reject(response);
                });
                return deferred.promise;
              }],
              categorias: ['$q', 'Terminos', function($q, Terminos){
                var deferred = $q.defer();
                var params = {clase: 'CATEGORIA'};
                Terminos.query(params, 'termino').then(function(data){
                  deferred.resolve(data.items);
                }, function(response){
                  deferred.reject(response);
                });
                return deferred.promise;
              }],
              fechaActualizacionShape: ['$q', 'Dominios', function($q, Dominios){
                var deferred = $q.defer();
                Dominios.fechaActualizacionShape().then(function(data){
                  const date = new Date(data.fecha);
                  // Hace la correccion de la zona horaria del cliente
                  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                  deferred.resolve(date);
                }, function(response){
                  deferred.reject(response);
                });
                return deferred.promise;
              }]
            },
            views: {
              header: {
                templateUrl: 'app/modules/top_bar/top_bar.tpl.html',
                controller: 'TopBar.MainController'
              },
              megaMenu: {
                templateUrl: 'app/modules/mega_menu/mega_menu.tpl.html',
                controller: 'megaMenu.MainController'
              },
              content: {
                template: '<div ui-view=""></div>'
              },
              preFooter: {
                templateUrl: 'app/modules/footer/pre_footer.tpl.html'
              },
              footer: {
                templateUrl: 'app/modules/footer/footer.tpl.html',
                // controller: ['$scope', 'fechaActualizacionShape', function($scope, fechaActualizacionShape){
                controller: [function(){
                  //App.initComponents();
                  Layout.init();
                  QuickSidebar.init();

                  // Fecha actualizacion informacion
                  // const yesterday = new Date();
                  // yesterday.setDate(yesterday.getDate() - 1);
                  // $scope.yesterday = yesterday;

                  // Fecha actualizacion shapefile consolidado
                  // $scope.fechaActualizacionShape = fechaActualizacionShape;
                }]
              }
            }
          })
          .state('error404', {
            url: '/error404',
            templateUrl: 'app/layouts/error404.tpl.html',
            data: {
              bodyClass: 'page-404-3'
            }
          })
          .state('error500', {
            url: '/error500',
            templateUrl: 'app/layouts/error500.tpl.html',
            data: {
              bodyClass: 'page-500-full-page'
            },
            params: {error: null},  // Definicion de parametros ocultos
            controller: ['$scope', '$stateParams', function($scope, $stateParams){
              $scope.error = $stateParams.error;
            }]
          });

        // Underscore.string and Lo-Dash integration
        _.mixin(s.exports());

        // Hack para arreglar un bug que hay de select2 en los modales
        // @deprecated
        //$.fn.modal.Constructor.prototype.enforceFocus = function(){};
      }
    ])

    .run(['$rootScope', '$http', '$state', function($rootScope, $http, $state){
      $rootScope.$state = $state;

      /*$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
        debug.info('$stateChangeStart', fromState, toState);
      });*/

      /*$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
        debug.info('$stateChangeSuccess', fromState, toState);
      });*/

      $rootScope.$on('$stateNotFound', function(event, unfoundState, fromState, fromParams){
        debug.info('$stateNotFound', unfoundState);
        $state.go('error404');
      });

      $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error){
        debug.info('$stateChangeError', error);
        $state.go('error500', {error: error});
      });
    }]);
}(window.angular, window.s));

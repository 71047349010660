(function(angular){
  'use strict';

  angular.module('app.services', [])

    .factory('scrollHelper', ['$q', '$document',
      function($q, $document){
        return {
          top: function(top){
            var deferred = $q.defer();
            top = top || 126;
            if($document.scrollTop() > top){
              $document.scrollTopAnimated(top, 200).then(function(){
                //debug.info('scroll finish');
                deferred.resolve();
              });
            }else{
              deferred.resolve();
            }
            return deferred.promise;
          }
        };
      }
    ]);
}(window.angular));

(function(angular){
  'use strict';

  angular.module('forgotPassword', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.forgotPassword', {
            url: '/olvido-contrasena',
            templateUrl: 'app/modules/forgot_password/forgot_password.tpl.html',
            controller: 'ForgotPassword.MainController'
          });
      }
    ])

    .controller('ForgotPassword.MainController', ['$scope', 'toaster', 'scrollHelper', 'Form', 'User',
      function($scope, toaster, scrollHelper, Form, User){
        $scope.form = new Form();

        $scope.submit = function(){
          var model = $scope.form.getFinalModel();
          $scope.form.start();
          User.restorePassword(model).then(function(data){
            $scope.form.resetForm();
            $scope.forgotPasswordForm.$setPristine();

            var notification = 'Se ha enviado el formulario correctamente. Revise la bandeja de entrada de su correo electrónico para ver las instrucciones que debe seguir.';
            toaster.pop('success', '', notification);
          }, function(response){
            $scope.form.setErrorResponse(response);
            scrollHelper.top();
          });
        };

        $scope.form.resetForm(false, 'Olvido de contraseña');
        if($scope.forgotPasswordForm){
          $scope.forgotPasswordForm.$setPristine();
        }
      }
    ]);
}(window.angular));

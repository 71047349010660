(function(angular) {
  'use strict';

  angular.module('home', [])

    .config(['$stateProvider', function($stateProvider){
      $stateProvider
        .state('main.views.home', {
          url: '/',
          templateUrl: 'app/modules/home/home.tpl.html',
          controller: 'Home.MainController'
        });
    }])

    .controller('Home.MainController', ['$scope', '$timeout', 'ServerRequest', 'Imagenes',
      function($scope, $timeout, ServerRequest, Imagenes){
        $scope.imagesSR = new ServerRequest();

        // $timeout(function(){
        //   var $slides = $('.slides');
        //   /*if($slides.superslides){
        //     debug.info('destroy');
        //     $slides.superslides('destroy');
        //   }else{
        //     debug.info('no destroy', $slides.superslides);
        //   }*/
        //   $slides.superslides({
        //     //play: 10000
        //   });
        // });
        //
        // /*$scope.swiperOptions = {
        //   autoHeight: true,
        //   pagination: '.swiper-pagination',
        //   paginationClickable: true,
        //   nextButton: '.swiper-button-next',
        //   prevButton: '.swiper-button-prev',
        //   spaceBetween: 30,
        //   loop: true,
        //   autoplay: 10000,
        //   // Disable preloading of all images
        //   preloadImages: false,
        //   // Enable lazy loading
        //   lazyLoading: true
        // };*/

        $scope.galleryOptions = {
          //gallery_theme: 'slider',
          gallery_theme: 'grid',
          gallery_width: 1100,
          gallery_height: 410,
          theme_panel_position: 'right'
        };

        $scope.imagesSR.start();
        Imagenes.all({pagina: 1, limite: 10, orden: '_RANDOM'})
          .then(function(data){
            $scope.imagesSR.success();
            $scope.images = data.items;
            $scope.images.unshift({
              id: 0,
              url: '/img/index.jpg',
              tamano: 7072999,
              mime: 'image/jpeg',
              creditos: 'PNN',
              alto: 445,
              ancho: 960
            });
            debug.info('gallery success', $scope.images);
          }, function(response){
            $scope.imagesSR.setErrorResponse(response);
          });
      }
    ]);
}(window.angular));

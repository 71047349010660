(function(angular){
  'use strict';

  angular.module('signUp', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.signUp', {
            url: '/registro',
            templateUrl: 'app/modules/sign_up/sign_up.tpl.html',
            resolve: {
              organizations: ['$q', 'Organizaciones', function($q, Organizaciones){
                var deferred = $q.defer();
                Organizaciones.query().then(function(data){
                  deferred.resolve(data.items);
                }, function(response){
                  deferred.reject(response);
                });
                return deferred.promise;
              }]
            },
            controller: 'SignUp.MainController'
          });
      }
    ])

    .controller('SignUp.MainController', ['$scope', 'toaster', 'scrollHelper', 'Form', 'User', 'organizations',
      function($scope, toaster, scrollHelper, Form, User, organizations){
        $scope.organizations = organizations;

        $scope.form = new Form();

        $scope.submit = function(){
          var model = $scope.form.getFinalModel();
          $scope.form.start();
          User.signUp(model).then(function(data){
            $scope.form.resetForm();
            $scope.signUpForm.$setPristine();

            var notification = 'Se ha enviado el formulario correctamente';
            toaster.pop('success', '', notification);
          }, function(response){
            $scope.form.setErrorResponse(response);
            scrollHelper.top();
          });
        };

        $scope.form.resetForm(false, 'Formulario de registro');
        if($scope.signUpForm){
          $scope.signUpForm.$setPristine();
        }
      }
    ]);
}(window.angular));

(function(angular){
  'use strict';

  angular.module('tests.requests', [])

    .factory('TestModels', ['$q', '$http', '$timeout',
      function($q, $http, $timeout){
        return {
          query: function(temp, params){
            var deferred = $q.defer();

            if(temp !== 'error'){
              if(params && params.cursor === 'ClAKLgoIc2FuX25hbWUSIhogZGlhbmFfY2Fyb2xpbmFfcm9kcmlndWV6X2FsZWdyaWESGmoKc35zdWxhLWRldnIMCxIEVXNlchjX-CcMGAAgAA=='){
                temp = _.sprintf('%s_cursor', temp);
              }else{
                temp = _.sprintf('%s', temp);
              }
            }

            $http.get(_.sprintf('/json/tests/query/%s.json', temp), {
              params: params,
              responseType: 'json'
            }).then(function(response){
              $timeout(function(){
                deferred.resolve(response.data);
              }, 1000);
            }, function(response){
              $timeout(function(){
                deferred.reject(response);
              }, 1000);
            });

            return deferred.promise;
          },
          errorQuery: function(){
            var deferred = $q.defer();

            $http.get('/json/tests/query/error.json', {
              responseType: 'json'
            }).then(function(response){
              $timeout(function(){
                deferred.resolve(response.data);
              }, 1000);
            }, function(response){
              $timeout(function(){
                deferred.reject(response);
              }, 1000);
            });

            return deferred.promise;
          },
          get: function(modelId){
            var deferred = $q.defer();

            $http.get(_.sprintf('/json/tests/get/%s.json', modelId), {
              responseType: 'json'
            }).then(function(response){
              $timeout(function(){
                deferred.resolve(response.data);
              }, 1000);
            }, function(response){
              $timeout(function(){
                deferred.reject(response);
              }, 1000);
            });

            return deferred.promise;
          },
          save: function(model, temp){
            var deferred = $q.defer();

            $http.get(_.sprintf('/json/tests/save/%s.json', temp), {
              params: model,
              responseType: 'json'
            }).then(function(response){
              $timeout(function(){
                if(response.data && response.data.error_code){
                  deferred.reject(response);
                }else{
                  deferred.resolve(response.data);
                }
              }, 1000);
            }, function(response){
              $timeout(function(){
                deferred.reject(response);
              }, 1000);
            });

            return deferred.promise;
          },
          errorSave: function(model){
            var deferred = $q.defer();

            $http.get('/json/tests/save/error.json', {
              params: model,
              responseType: 'json'
            }).then(function(response){
              $timeout(function(){
                if(response.data && response.data.error_code){
                  deferred.reject(response);
                }else{
                  deferred.resolve(response.data);
                }
              }, 1000);
            }, function(response){
              $timeout(function(){
                deferred.reject(response);
              }, 1000);
            });

            return deferred.promise;
          },
          action: function(model){
            var deferred = $q.defer();

            $http.get('/json/tests/action/some_action.json', {
              params: model,
              responseType: 'json'
            }).then(function(response){
              $timeout(function(){
                deferred.resolve(response.data);
              }, 1000);
            }, function(response){
              $timeout(function(){
                deferred.reject(response);
              }, 1000);
            });

            return deferred.promise;
          },
          errorAction: function(model){
            var deferred = $q.defer();

            $http.get('/json/tests/action/error.json', {
              params: model,
              responseType: 'json'
            }).then(function(response){
              $timeout(function(){
                deferred.resolve(response.data);
              }, 1000);
            }, function(response){
              $timeout(function(){
                deferred.reject(response);
              }, 1000);
            });

            return deferred.promise;
          },
          okUploadUrl: function(url){
            var deferred = $q.defer();

            $http.get(url, {
              responseType: 'json'
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          errorUploadUrl: function(){
            var deferred = $q.defer();

            $http.get('/json/tests/upload_url/error.json', {
              responseType: 'json'
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .config(['$stateProvider', function($stateProvider){
      $stateProvider
        .state('main.views.tests.requests', {
          url: '/requests',
          templateUrl: 'app/modules/tests/requests/main.tpl.html',
          controller: ['$scope', function($scope){
            $scope.common = {};
          }]
        })
        .state('main.views.tests.requests.list', {
          url: '/list',
          params: {topScroll: false},  // Definicion de parametros ocultos
          templateUrl: 'app/modules/tests/requests/list.tpl.html',
          controller: 'Tests.Requests.ListController'
        })
        .state('main.views.tests.requests.simple', {
          url: '/simple',
          params: {topScroll: false},  // Definicion de parametros ocultos
          templateUrl: 'app/modules/tests/requests/simple.tpl.html',
          controller: 'Tests.Requests.SimpleController'
        })
        .state('main.views.tests.requests.confirm', {
          url: '/confirm',
          params: {topScroll: false},  // Definicion de parametros ocultos
          templateUrl: 'app/modules/tests/requests/confirm_view.tpl.html',
          controller: 'Tests.Requests.ConfirmController'
        })
        .state('main.views.tests.requests.form', {
          url: '/form',
          abstract: true,
          params: {topScroll: false},  // Definicion de parametros ocultos
          templateUrl: 'app/modules/tests/requests/form_container.tpl.html',
          controller: 'Tests.Requests.FormContainerController'
        })
        .state('main.views.tests.requests.form.insert', {
          url: '',
          templateUrl: 'app/modules/tests/requests/form.tpl.html',
          resolve: {
            model: function(){
              return null;
            }
          },
          controller: 'Tests.Requests.FormController'
        })
        .state('main.views.tests.requests.form.update', {
          url: '/:modelId',
          templateUrl: 'app/modules/tests/requests/form.tpl.html',
          resolve: {
            model: ['$q', '$stateParams', 'TestModels',
              function($q, $stateParams, TestModels){
                var deferred = $q.defer();
                TestModels.get($stateParams.modelId).then(function(data){
                  if(data.model){
                    deferred.resolve(data.model);
                  }else{
                    deferred.reject();
                  }
                }, function(response){
                  deferred.reject(response);
                });
                return deferred.promise;
              }
            ]
          },
          controller: 'Tests.Requests.FormController'
        })
        .state('main.views.tests.requests.upload', {
          url: '/upload',
          params: {topScroll: false},  // Definicion de parametros ocultos
          templateUrl: 'app/modules/tests/requests/upload_view.tpl.html',
          resolve: {
            model: ['$q', 'TestModels', function($q, TestModels){
              var deferred = $q.defer();
              TestModels.get(123).then(function(data){
                if(data.model){
                  deferred.resolve(data.model);
                }else{
                  deferred.reject();
                }
              }, function(response){
                deferred.reject(response);
              });
              return deferred.promise;
            }]
          },
          controller: 'Tests.Requests.UploadController'
        });
    }])

    .directive('testModelItem', function(){
      return {
        restrict: 'A',
        scope: {
          model: '=testModelItem'
        },
        templateUrl: 'app/modules/tests/requests/model_item.tpl.html'
      };
    })

    .controller('Tests.Requests.ListController', ['$scope', 'Paginator', 'TestModels', '$stateParams',
      function($scope, Paginator, TestModels, $stateParams){
        if(!$scope.common.paginator){
          $scope.common.paginator = new Paginator(TestModels.query, 10);
        }

        $scope.doSearch = function(filters){
          var params = {_postopts: 'full'};
          angular.extend(params, filters || {});
          var opt = params.opt;
          delete params.opt;
          $scope.common.paginator.query(opt, params);
        };

        // Muestra la lista
        //if($scope.common.paginator.serverRequest.status === 'initialized'){
        //  $scope.common.paginator.query();
        //}
      }
    ])

    .controller('Tests.Requests.SimpleController', ['$scope', 'toaster', 'ServerRequest', 'TestModels',
      function($scope, toaster, ServerRequest, TestModels){
        $scope.serverRequest1 = new ServerRequest();
        $scope.serverRequest2 = new ServerRequest();
        $scope.result = null;

        $scope.containerOkRequest = function(){
          $scope.serverRequest1.start();
          TestModels.query('1page').then(function(data){
            $scope.serverRequest1.success();
            $scope.result = data.collection;
          }, function(response){
            $scope.serverRequest1.setErrorResponse(response);
          });
        };

        $scope.containerErrorRequest = function(){
          $scope.serverRequest1.start();
          TestModels.errorQuery().then(function(data){
            $scope.serverRequest1.success();
          }, function(response){
            $scope.serverRequest1.setErrorResponse(response);
          });
        };

        $scope.okRequest = function(){
          $scope.serverRequest2.start();
          TestModels.query('1page').then(function(data){
            $scope.serverRequest2.success();

            var notification = 'Llamado OK!';
            toaster.pop('success', '', notification);
          }, function(response){
            $scope.serverRequest2.setErrorResponse(response);
            toaster.pop($scope.serverRequest2.error.type, '', $scope.serverRequest2.getFullError());
          });
        };

        $scope.errorRequest = function(){
          $scope.serverRequest2.start();
          TestModels.errorQuery().then(function(data){
            $scope.serverRequest2.success();
          }, function(response){
            $scope.serverRequest2.setErrorResponse(response);
            toaster.pop($scope.serverRequest2.error.type, '', $scope.serverRequest2.getFullError());
          });
        };
      }
    ])

    .controller('Tests.Requests.ConfirmController', ['$scope', '$modal', 'toaster', 'ServerRequest', 'TestModels',
      function($scope, $modal, toaster, ServerRequest, TestModels){
        var modal;

        var initModal = function(){
          modal = $modal({
            show: false,
            backdrop: 'static',
            templateUrl: 'app/modules/tests/requests/confirm.tpl.html'
          });

          modal.$scope.serverRequest = new ServerRequest();

          modal.$scope.okConfirm = function(model){
            modal.$scope.serverRequest.start();
            TestModels.action(model).then(function(data){
              // Oculta el modal
              modal.$scope.$hide();

              var notification = 'Acción ejecutada correctamente';
              toaster.pop('success', '', notification);
            }, function(response){
              modal.$scope.serverRequest.setErrorResponse(response);
            });
          };

          modal.$scope.errorConfirm = function(model){
            modal.$scope.serverRequest.start();
            TestModels.errorAction(model).then(function(data){
              // Oculta el modal
              modal.$scope.$hide();

              var notification = 'Acción ejecutada correctamente';
              toaster.pop('success', '', notification);
            }, function(response){
              modal.$scope.serverRequest.setErrorResponse(response);
            });
          };

          modal.$scope.close = function(){
            // Oculta el modal
            modal.$scope.$hide();
          };
        };

        $scope.okRequest = function(model){
          modal.$scope.scope = 'ok';
          modal.$scope.serverRequest.reset();
          modal.$scope.model = model;

          // Muestra el modal
          modal.$promise.then(function(){
            modal.show();
          });
        };

        $scope.errorRequest = function(model){
          modal.$scope.scope = 'error';
          modal.$scope.serverRequest.reset();
          modal.$scope.model = model;

          // Muestra el modal
          modal.$promise.then(function(){
            modal.show();
          });
        };

        initModal();
      }
    ])

    .controller('Tests.Requests.FormContainerController', ['$scope', '$modal', 'toaster', 'Form', 'TestModels',
      function($scope, $modal, toaster, Form, TestModels){
        var modal;

        $scope.model = {
          key: 123,
          name: 'Pepe',
          lastname: 'Perez',
          email: 'pepe@prueba.com'
        };

        var initModal = function(){
          modal = $modal({
            show: false,
            backdrop: 'static',
            templateUrl: 'app/modules/tests/requests/modal_form.tpl.html'
          });

          modal.$scope.form = new Form();

          modal.$scope.okSubmit = function(){
            var model = modal.$scope.form.getFinalModel();
            modal.$scope.form.start();
            TestModels.save(model, 1).then(function(data){
              // Oculta el modal
              modal.$scope.$hide();

              var notification = 'Se ha enviado el formulario correctamente';
              toaster.pop('success', '', notification);
            }, function(response){
              modal.$scope.form.setErrorResponse(response);
              // @TODO subir el scroll del modal para ver el mensaje de error
            });
          };

          modal.$scope.errorSubmit = function(){
            var model = modal.$scope.form.getFinalModel();
            modal.$scope.form.start();
            TestModels.save(model, 2).then(function(data){
              // Oculta el modal
              modal.$scope.dismiss();

              var notification = 'Se ha enviado el formulario correctamente';
              toaster.pop('success', '', notification);
            }, function(response){
              modal.$scope.form.setErrorResponse(response);
              // @TODO subir el scroll del modal para ver el mensaje de error
            });
          };

          modal.$scope.errorRequest = function(){
            var model = modal.$scope.form.getFinalModel();
            modal.$scope.form.start();
            TestModels.errorSave(model).then(function(data){
              // Oculta el modal
              modal.$scope.dismiss();

              var notification = 'Se ha enviado el formulario correctamente';
              toaster.pop('success', '', notification);
            }, function(response){
              modal.$scope.form.setErrorResponse(response);
              // @TODO subir el scroll del modal para ver el mensaje de error
            });
          };

          modal.$scope.close = function(){
            // Oculta el modal
            modal.$scope.$hide();
          };
        };

        $scope.showForm = function(model){
          modal.$scope.form.resetForm(model, 'Nuevo registro', 'Edición de registro');

          // Opciones de los selects
          // En caso de ya tener a mano las opciones del select
          // @TODO
          //modal.$scope.form.fields.tag_keys = {
          //  options: tags
          //};

          // Consulta las opciones para el select
          // @TODO
          // SelectHelper...

          if(!modal.$scope.form.isNew){
            // Se hace el mapeo de los atributos del modelo a master
            modal.$scope.form.master = {
              name: model.name,
              lastname: model.lastname,
              email: model.email
            };
            modal.$scope.form.syncModel();
          }

          // Muestra el modal
          modal.$promise.then(function(){
            modal.show();
          });
        };

        initModal();
      }
    ])

    .controller('Tests.Requests.FormController', ['$scope', 'toaster', 'scrollHelper', 'Form', 'TestModels', 'model',
      function($scope, toaster, scrollHelper, Form, TestModels, model){
        debug.group('Tests.Requests.FormController');
        debug.info('model', model);
        debug.groupEnd();

        $scope.form = new Form();

        $scope.okSubmit = function(){
          var model = $scope.form.getFinalModel();
          $scope.form.start();
          TestModels.save(model, 1).then(function(data){
            $scope.form.resetForm();
            $scope.testForm.$setPristine();

            var notification = 'Se ha enviado el formulario correctamente';
            toaster.pop('success', '', notification);
          }, function(response){
            $scope.form.setErrorResponse(response);
            scrollHelper.top();
          });
        };

        $scope.errorSubmit = function(){
          var model = $scope.form.getFinalModel();
          $scope.form.start();
          TestModels.save(model, 2).then(function(data){
            $scope.form.resetForm();
            $scope.testForm.$setPristine();

            var notification = 'Se ha enviado el formulario correctamente';
            toaster.pop('success', '', notification);
          }, function(response){
            $scope.form.setErrorResponse(response);
            scrollHelper.top();
          });
        };

        $scope.errorRequest = function(){
          var model = $scope.form.getFinalModel();
          $scope.form.start();
          TestModels.errorSave(model).then(function(data){
            $scope.form.resetForm();
            $scope.testForm.$setPristine();

            var notification = 'Se ha enviado el formulario correctamente';
            toaster.pop('success', '', notification);
          }, function(response){
            $scope.form.setErrorResponse(response);
            scrollHelper.top();
          });
        };

        $scope.form.resetForm(model, 'Nuevo registro', 'Edición de registro');
        if($scope.testForm){
          $scope.testForm.$setPristine();
        }

        // Opciones de los selects
        // En caso de ya tener a mano las opciones del select
        // @TODO
        //$scope.form.fields.tag_keys = {
        //  options: tags
        //};

        // Consulta las opciones para el select
        // @TODO
        // SelectHelper...

        if(!$scope.form.isNew){
          // Se hace el mapeo de los atributos del modelo a master
          $scope.form.master = {
            name: model.name,
            lastname: model.lastname,
            email: model.email
          };
          $scope.form.syncModel();
        }
      }
    ])

    .controller('Tests.Requests.UploadController', ['$scope', '$q', '$modal', 'toaster', 'Upload', 'ServerRequest', 'TestModels', 'model',
      function($scope, $q, $modal, toaster, Upload, ServerRequest, TestModels, model){
        debug.group('Tests.Requests.UploadController');
        debug.info('model', model);
        debug.groupEnd();

        $scope.model = model;

        // Crea un scope aislado para el modal de subida de archivos
        var uploadScope = $scope.$new(true);

        uploadScope.serverRequest = new ServerRequest();

        uploadScope.fileSelected = function(files, event, model, flag){
          if(files.length === 0){
            return;
          }

          var file = files[0];
          var uploadUrl = _.sprintf('/json/tests/upload_url/%s.json', model.key);
          uploadScope.progressStyle = {width: '0%'};
          uploadScope.serverRequest.start();
          TestModels[flag + 'UploadUrl'](uploadUrl).then(function(data){
            debug.info('uploadUrl success', data);
            Upload.upload({
              url: data.upload_url,
              file: file,
              fileFormDataName: 'file'
            }).progress(function(event){
              var progressPercentage = parseInt(100.0 * event.loaded / event.total, 10);
              uploadScope.progressStyle = {width: progressPercentage + '%'};
            }).success(function(data, status, headers, config){
              uploadScope.serverRequest.success();

              // Actualiza el modelo en la lista
              $scope.common.paginator.updateModel(data.model);

              // Cierra el modal de subida de archivo
              uploadScope.dismiss();

              var notification = 'Se subió el archivo correctamente';
              toaster.pop('success', '', notification);
            }).error(function(data, status){
              uploadScope.serverRequest.setErrorResponse({data: data});
            });
          }, function(response){
            uploadScope.serverRequest.setErrorResponse(response);
          });
        };

        uploadScope.cancel = function(){
          // Cierra el modal
          uploadScope.dismiss();
        };

        // Inicializa el modal de subida de archivos
        var uploadModal = $modal({
          template: 'app/modules/tests/requests/upload.tpl.html',
          persist: true,
          show: false,
          backdrop: 'static',
          scope: uploadScope
        });

        $scope.okUploadFile = function(model){
          uploadScope.serverRequest.reset();
          uploadScope.title = 'Subir archivo';
          uploadScope.model = model;
          uploadScope.flag = 'ok';

          // Muestra el modal
          $q.when(uploadModal).then(function(modalEl){
            modalEl.modal('show');
          });
        };

        $scope.errorUploadFile = function(model){
          uploadScope.serverRequest.reset();
          uploadScope.title = 'Subir archivo';
          uploadScope.model = model;
          uploadScope.flag = 'error';

          // Muestra el modal
          $q.when(uploadModal).then(function(modalEl){
            modalEl.modal('show');
          });
        };
      }
    ]);
}(window.angular));

(function(angular){
  'use strict';

  angular.module('tests', ['tests.requests'])

    .config(['$stateProvider', function($stateProvider){
      $stateProvider
        .state('main.views.tests', {
          url: '/tests',
          abstract: true,
          template: '<div ui-view=""></div>'
        });
    }]);
}(window.angular));

(function(angular){
  'use strict';

  angular.module('faq', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.faq', {
            url: '/preguntas-frecuentes',
            templateUrl: 'app/modules/faq/main.tpl.html'
          });
      }
    ]);
}(window.angular));

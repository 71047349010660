(function(angular){
  'use strict';

  // @TODO: arreglar esto para no tenerlo quemado
  var develMode = false;

  angular.module('config', [])

    .factory('backend', function(){
      // var domain = '{{BACKEND_SERVER_NAME}}';
      // if(_.startsWith(domain, '{') && _.endsWith(domain, '}')){
      //   // No pudo resolver la variable de Jinja2
      //   domain = 'runapbackend.parquesnacionales.gov.co';
      //   //domain = 'back.runap2';
      // }
      // return _.sprintf('//%s/v2', domain);

      let url;
      if (develMode) {
        url = 'http://back.runap/v2';
      } else {
        url = 'https://runapbackend.parquesnacionales.gov.co/v2';
      }
      return url;
    })

    .factory('runapv1', function(){
      // var domain = 'runapadmin.parquesnacionales.gov.co';
      // //var domain = 'local.runap';
      // return _.sprintf('//%s', domain);

      let url;
      if (develMode) {
        url = 'http://local.runapadmin';
      } else {
        url = 'https://runapadmin.parquesnacionales.gov.co';
      }
      return url;
    })

    .factory('sinap', function(){
      return 'https://sinap-api.parquesnacionales.gov.co/api/v1';
    });
}(window.angular));

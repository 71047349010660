(function(angular){
  'use strict';

  angular.module('buttons.directives', [])

    .directive('simpleButton', function(){
      return {
        restrict: 'A',
        replace: true,
        transclude: true,
        templateUrl: 'app/common/directives/buttons/simple.tpl.html'
      };
    })

    .directive('blockerButton', function(){
      return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
          serverRequest: '=blockerButton',
          workingLabel: '@'
        },
        templateUrl: 'app/common/directives/buttons/blocker.tpl.html'
      };
    })

    .directive('submitButton', function(){
      return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
          formObj: '=submitButton',
          form: '=',
          workingLabel: '@'
        },
        templateUrl: 'app/common/directives/buttons/submit.tpl.html'
      };
    })

    .directive('paginatorButton', function(){
      return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
          paginator: '=paginatorButton'
        },
        templateUrl: 'app/common/directives/buttons/paginator.tpl.html'
      };
    });
}(window.angular));

(function(angular, AmCharts){
  'use strict';

  angular.module('charts', [])

    .directive('chart', function(){
      return {
        restrict: 'A',
        replace: true,
        scope: {
          options: '=chart',
          serverRequest: '='
        },
        templateUrl: 'app/common/directives/charts/chart.tpl.html',

        link: function(scope, element, attrs){
          var $chart, chart;
          scope.$watch('serverRequest.status', function(newValue, oldValue, scope){
            if(newValue !== 'ok'){
              if(chart){
                // Destruye la grafica anterior para liberar memoria
                chart.destroy();
              }
            }else{
              $chart = element.find('div.chart');
              var options = angular.copy(scope.options);
              angular.merge(options, {credits: {enabled: false}});
              debug.debug('highcharts options', options);
              $chart.highcharts(options);
              chart = $chart.highcharts();
              if(!chart.hasData()){
                chart.showNoData('No hay datos para graficar');
              }else{
                chart.hideNoData();
              }
            }
          });

          scope.$on('$destroy', function(){
            if(chart){
              // Destruye la grafica actual para liberar memoria
              chart.destroy();
            }
          });
        }
      };
    })

    .directive('stock', function(){
      return {
        restrict: 'A',
        replace: true,
        scope: {
          options: '=stock',
          serverRequest: '='
        },
        templateUrl: 'app/common/directives/charts/chart.tpl.html',

        link: function(scope, element, attrs){
          var $chart, chart;
          scope.$watch('serverRequest.status', function(newValue, oldValue, scope){
            if(newValue !== 'ok'){
              if(chart){
                // Destruye la grafica anterior para liberar memoria
                chart.destroy();
              }
            }else{
              $chart = element.find('div.chart');
              var options = angular.copy(scope.options);
              angular.merge(options, {credits: {enabled: false}});
              debug.debug('highstock options', options);
              $chart.highcharts('StockChart', options);
              chart = $chart.highcharts();
              if(!chart.hasData()){
                chart.showNoData('No hay datos para graficar');
              }else{
                chart.hideNoData();
              }
            }
          });

          scope.$on('$destroy', function(){
            if(chart){
              // Destruye la grafica actual para liberar memoria
              chart.destroy();
            }
          });
        }
      };
    })

    .directive('amChart', function($timeout){
      return {
        restrict: 'A',
        replace: true,
        scope: {
          id: '@amChart',
          serverRequest: '=',
          values: '=',
          colors: '='
        },
        templateUrl: 'app/common/directives/charts/am_chart.tpl.html',

        link: function(scope, element, attrs){
          var chart;
          scope.$watch('serverRequest.status', function(newValue, oldValue, scope){
            if(newValue === 'ok'){
              $timeout(function(){
                // Percent chart
                //var chart = new AmCharts.AmSerialChart(AmCharts.themes.none);
                chart = new AmCharts.AmSerialChart({});
                chart.dataProvider = [{
                  x: 1,
                  y1: scope.values[0],
                  y2: scope.values[1]
                }];
                chart.categoryField = 'x';
                chart.rotate = true;
                chart.autoMargins = false;
                chart.marginLeft = 0;
                chart.marginRight = 0;
                chart.marginTop = 0;
                chart.marginBottom = 0;

                var graph1 = new AmCharts.AmGraph();
                graph1.valueField = 'y1';
                graph1.type = 'column';
                graph1.fillAlphas = 0.8;
                graph1.fillColors = scope.colors[0];
                graph1.gradientOrientation = 'horizontal';
                graph1.lineColor = '#FFFFFF';
                graph1.showBalloon = false;
                chart.addGraph(graph1);

                var graph2 = new AmCharts.AmGraph();
                graph2.valueField = 'y2';
                graph2.type = 'column';
                graph2.fillAlphas = 0.8;
                graph2.fillColors = scope.colors[1];
                graph2.lineColor = '#FFFFFF';
                graph2.showBalloon = false;
                chart.addGraph(graph2);

                var valueAxis = new AmCharts.ValueAxis();
                valueAxis.gridAlpha = 0;
                valueAxis.axisAlpha = 0;
                valueAxis.stackType = '100%'; // this is set to achieve that column would occupie 100% of the chart area
                chart.addValueAxis(valueAxis);

                var categoryAxis = chart.categoryAxis;
                categoryAxis.gridAlpha = 0;
                categoryAxis.axisAlpha = 0;

                chart.write(scope.id);
              }, 250);
            }
          });
        }
      };
    });
}(window.angular, window.AmCharts));

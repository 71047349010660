(function(angular){
  'use strict';

  angular.module('string.services', [])

    .factory('StringService', function(){
      return {
        normalize: function(text){
          var searchValue = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç';
          var newValue    = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc';
          var i;
          for(i in searchValue){
            text = text.replace(searchValue.charAt(i), newValue.charAt(i));
          }
          return text.replace(searchValue, newValue);
        }
      };
    });

    // @TODO: formateo de URLs
}(window.angular));

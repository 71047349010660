(function(angular){
  'use strict';

  angular.module('form.services', [])

    .factory('Form', ['ServerRequest', function(ServerRequest){
      var Form = function(){
        this.serverRequest = new ServerRequest();
        this.resetForm();
      };

      Form.prototype.resetForm = function(model, newTitle, updateTitle){
        this.isNew = !model;
        if(newTitle || updateTitle){
          this.title = this.isNew ? newTitle : updateTitle;
        }
        this.serverRequest.reset();
        this.key = this.isNew ? false : model.key;
        this.master = {};
        this.model = {};
        this.fields = {};
      };

      Form.prototype.syncModel = function(){
        this.model = angular.copy(this.master);
      };

      Form.prototype.syncMaster = function(){
        this.master = angular.copy(this.model);
      };

      Form.prototype.isUnchanged = function(){
        return angular.equals(this.model, this.master);
      };

      Form.prototype.getFinalModel = function(noCsrf){
        var finalModel = angular.copy(this.model);
        if(!this.isNew){
          finalModel.key = this.key;
        }
        if(noCsrf !== true){
          // @TODO: setear este atributo correctamente
          finalModel.csrf = 'foo';
        }
        return finalModel;
      };

      Form.prototype.start = function(){
        this.serverRequest.start();
        this.syncMaster();
      };

      Form.prototype.setErrorResponse = function(response){
        this.serverRequest.setErrorResponse(response, true);
      };

      Form.prototype.resetFieldError = function(field){
        if(!angular.isUndefined(this.serverRequest.error.formFields[field])){
          delete this.serverRequest.error.formFields[field];
        }
      };

      return Form;
    }])

    // @TODO
    //.factory('SelectHelper', function($q, $timeout, Organizations, Contexts, Tags, Schemas, SchemaProperties, TreesAdmin, NodeLabelsAdmin, Indicators, Data, DataMaps, Datasets, DatasetTables, Modifiers){
    .factory('SelectHelper', ['$q', '$timeout', 'Organizations', 'Contexts', 'Tags', 'Schemas', 'SchemaProperties', 'Batches', 'TreesAdmin', 'NodeLabelsAdmin', 'Indicators', 'Data', 'DataMaps', 'Datasets', 'DatasetTables',
      function($q, $timeout, Organizations, Contexts, Tags, Schemas, SchemaProperties, Batches, TreesAdmin, NodeLabelsAdmin, Indicators, Data, DataMaps, Datasets, DatasetTables){
        return {
          organizations: function(form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && !angular.isUndefined(form.fields[field].options)){
              form.fields[field].status = 'loading';
              $timeout(function(){
                form.fields[field].status = 'ok';
                deferred.resolve(form.fields[field].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field].status = 'loading';
              Organizations.cachedQuery(params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field].status = 'ok';
                  form.fields[field].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select organizations error', response);
                form.fields[field].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          territorials: function(form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && !angular.isUndefined(form.fields[field].options)){
              form.fields[field].status = 'loading';
              $timeout(function(){
                form.fields[field].status = 'ok';
                deferred.resolve(form.fields[field].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }

              params = angular.extend(params || {}, {label: 'territorial'});
              params = _.defaults(params, {
                perpage: 1000
              });

              form.fields[field].status = 'loading';
              // @TODO: cachedQuery
              Contexts.query(params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field].status = 'ok';
                  form.fields[field].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select territorial error', response);
                form.fields[field].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          areas: function(territorialKey, form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && form.fields[field][territorialKey]
              && !angular.isUndefined(form.fields[field][territorialKey].options)){
              form.fields[field][territorialKey].status = 'loading';
              $timeout(function(){
                form.fields[field][territorialKey].status = 'ok';
                deferred.resolve(form.fields[field][territorialKey].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }
              if(!form.fields[field][territorialKey]){
                form.fields[field][territorialKey] = {};
              }

              params = angular.extend(params || {}, {
                parent_key: territorialKey,
                label: 'area'
              });
              params = _.defaults(params, {
                perpage: 1000
              });

              form.fields[field][territorialKey].status = 'loading';
              // @TODO: cachedQuery
              Contexts.query(params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field][territorialKey].status = 'ok';
                  form.fields[field][territorialKey].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select areas error', response);
                form.fields[field][territorialKey].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          thematicGroups: function(form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && !angular.isUndefined(form.fields[field].options)){
              form.fields[field].status = 'loading';
              $timeout(function(){
                form.fields[field].status = 'ok';
                deferred.resolve(form.fields[field].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field].status = 'loading';
              // @TODO: cachedQuery
              Tags.query('grupo_tematico', params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field].status = 'ok';
                  form.fields[field].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select thematicGroups error', response);
                form.fields[field].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          roles: function(form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && !angular.isUndefined(form.fields[field].options)){
              form.fields[field].status = 'loading';
              $timeout(function(){
                form.fields[field].status = 'ok';
                deferred.resolve(form.fields[field].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field].status = 'loading';
              // @TODO: cachedQuery
              Tags.query('rol', params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field].status = 'ok';
                  form.fields[field].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select roles error', response);
                form.fields[field].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          schemas: function(form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && !angular.isUndefined(form.fields[field].options)){
              form.fields[field].status = 'loading';
              $timeout(function(){
                form.fields[field].status = 'ok';
                deferred.resolve(form.fields[field].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field].status = 'loading';
              // @TODO: cachedQuery
              Schemas.query(params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field].status = 'ok';
                  form.fields[field].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select schemas error', response);
                form.fields[field].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          schemaProperties: function(schemaKey, form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && form.fields[field][schemaKey]
              && !angular.isUndefined(form.fields[field][schemaKey].options)){
              form.fields[field][schemaKey].status = 'loading';
              $timeout(function(){
                form.fields[field][schemaKey].status = 'ok';
                deferred.resolve(form.fields[field][schemaKey].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }
              if(!form.fields[field][schemaKey]){
                form.fields[field][schemaKey] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field][schemaKey].status = 'loading';
              // @TODO: cachedQuery
              SchemaProperties.query(schemaKey, params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field][schemaKey].status = 'ok';
                  form.fields[field][schemaKey].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select schemaProperties error', response);
                form.fields[field][schemaKey].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          batches: function(schemaKey, form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && form.fields[field][schemaKey]
              && !angular.isUndefined(form.fields[field][schemaKey].options)){
              form.fields[field][schemaKey].status = 'loading';
              $timeout(function(){
                form.fields[field][schemaKey].status = 'ok';
                deferred.resolve(form.fields[field][schemaKey].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }
              if(!form.fields[field][schemaKey]){
                form.fields[field][schemaKey] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field][schemaKey].status = 'loading';
              // @TODO: cachedQuery
              Batches.query(schemaKey, params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field][schemaKey].status = 'ok';
                  form.fields[field][schemaKey].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select batches error', response);
                form.fields[field][schemaKey].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          trees: function(form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && !angular.isUndefined(form.fields[field].options)){
              form.fields[field].status = 'loading';
              $timeout(function(){
                form.fields[field].status = 'ok';
                deferred.resolve(form.fields[field].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field].status = 'loading';
              // @TODO: cachedQuery
              TreesAdmin.query(params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field].status = 'ok';
                  form.fields[field].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select trees error', response);
                form.fields[field].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          nodeLabels: function(treeKey, form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && form.fields[field][treeKey]
              && !angular.isUndefined(form.fields[field][treeKey].options)){
              form.fields[field][treeKey].status = 'loading';
              $timeout(function(){
                form.fields[field][treeKey].status = 'ok';
                deferred.resolve(form.fields[field][treeKey].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }
              if(!form.fields[field][treeKey]){
                form.fields[field][treeKey] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field][treeKey].status = 'loading';
              // @TODO: cachedQuery
              NodeLabelsAdmin.query(treeKey, params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field][treeKey].status = 'ok';
                  form.fields[field][treeKey].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select nodeLabels error', response);
                form.fields[field][treeKey].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          indicators: function(scope){
            scope.indicatorStatus = 'loading';
            Indicators.query().then(function(response){
              scope.indicatorStatus = 'ok';
              scope.indicatorOptions = response.data.collection;
            }, function(response){
              debug.info('Select indicators error', response);
              scope.indicatorStatus = 'error';
            });
          },

          dataBundles: function(indicatorVersionKey, form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && form.fields[field][indicatorVersionKey]
              && !angular.isUndefined(form.fields[field][indicatorVersionKey].options)){
              form.fields[field][indicatorVersionKey].status = 'loading';
              $timeout(function(){
                form.fields[field][indicatorVersionKey].status = 'ok';
                deferred.resolve(form.fields[field][indicatorVersionKey].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }
              if(!form.fields[field][indicatorVersionKey]){
                form.fields[field][indicatorVersionKey] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field][indicatorVersionKey].status = 'loading';
              // @TODO: cachedQuery
              Data.dataBundles.query(indicatorVersionKey, params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field][indicatorVersionKey].status = 'ok';
                  form.fields[field][indicatorVersionKey].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select dataBundles error', response);
                form.fields[field][indicatorVersionKey].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          dataMaps: function(form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && !angular.isUndefined(form.fields[field].options)){
              form.fields[field].status = 'loading';
              $timeout(function(){
                form.fields[field].status = 'ok';
                deferred.resolve(form.fields[field].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field].status = 'loading';
              // @TODO: cachedQuery
              DataMaps.query(params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field].status = 'ok';
                  form.fields[field].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select dataMaps error', response);
                form.fields[field].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          datasets: function(form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && !angular.isUndefined(form.fields[field].options)){
              form.fields[field].status = 'loading';
              $timeout(function(){
                form.fields[field].status = 'ok';
                deferred.resolve(form.fields[field].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field].status = 'loading';
              // @TODO: cachedQuery
              Datasets.query(params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field].status = 'ok';
                  form.fields[field].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select datasets error', response);
                form.fields[field].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          datasetTables: function(datasetKey, form, field, params){
            var deferred = $q.defer();
            if(form.fields[field] && form.fields[field][datasetKey]
              && !angular.isUndefined(form.fields[field][datasetKey].options)){
              form.fields[field][datasetKey].status = 'loading';
              $timeout(function(){
                form.fields[field][datasetKey].status = 'ok';
                deferred.resolve(form.fields[field][datasetKey].options);
              }, 30);
            }else{
              if(!form.fields[field]){
                form.fields[field] = {};
              }
              if(!form.fields[field][datasetKey]){
                form.fields[field][datasetKey] = {};
              }

              params = _.defaults(params || {}, {
                perpage: 1000
              });

              form.fields[field][datasetKey].status = 'loading';
              // @TODO: cachedQuery
              DatasetTables.query(datasetKey, params).then(function(data){
                $timeout(function(){
                  var options = data.collection;
                  form.fields[field][datasetKey].status = 'ok';
                  form.fields[field][datasetKey].options = options;
                  deferred.resolve(options);
                }, 30);
              }, function(response){
                debug.info('Select datasetTables error', response);
                form.fields[field][datasetKey].status = 'error';
                deferred.reject();
              });
            }
            return deferred.promise;
          },

          modifiers: function(scope){
            scope.modifierStatus = 'loading';
            Modifiers.query({perpage: 100}).then(function(response){
              scope.modifierStatus = 'ok';
              scope.modifierOptions = response.data.collection;
            }, function(response){
              debug.info('Select modifiers error', response);
              scope.modifierStatus = 'error';
            });
          }
        };
      }
    ]);
}(window.angular));

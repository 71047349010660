(function(angular){
  'use strict';

  angular.module('string.filters', [])

    .filter('join', function(){
      return function(input, separator){
        if(!input || !angular.isArray(input) || input.length === 0){
          return '';
        }
        input = angular.copy(input);
        var cleanedSeparator = _.clean(separator);
        angular.forEach(input, function(value, i){
          if(value.indexOf(cleanedSeparator) !== -1){
            input[i] = _.sprintf('"%s"', value);
          }
        });
        return input.join(separator);
      };
    })

    // @TODO: mejorar este filtro para que no corte las palabras
    .filter('truncate', function(){
      return function(input, length){
        if(!angular.isString(input)){
          return '';
        }
        if(length === false){
          return input;
        }
        if(input.length > length){
          input = input.substring(0, length);
          // @TODO: implementar un filtro "strip" y utilizarlo aqui
          // Inicio strip
          if(input[length - 1] === ' '){
            input = input.substring(0, length - 1);
          }
          // Fin strip
          input += '...';
        }
        return input;
      };
    })

    .filter('strip', function(){
      return function(input){
        if(!angular.isString(input)){
          return '';
        }
        var i, pos = null;
        for(i = 0; i < input.length; i += 1){
          if(input[i] !== ' '){
            pos = i;
            break;
          }
        }
        if(pos !== null){
          input = input.substring(pos);
        }
        pos = null;
        for(i = input.length - 1; i >= 0; i -= 1){
          if(input[i] !== ' '){
            pos = i;
            break;
          }
        }
        if(pos !== null){
          input = input.substring(0, pos + 1);
        }
        return input;
      };
    });
}(window.angular));

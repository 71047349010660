(function(angular, Math){
  'use strict';

  angular.module('megaMenu', [])

    .controller('megaMenu.MainController', ['$scope', '$state', 'StringService', 'organizaciones', 'categorias', 'departamentos', 'backend', 'runapv1',
      function($scope, $state, StringService, organizaciones, categorias, departamentos, backend, runapv1){
        var third;

        $scope.runapv1 = runapv1;

        // Organizaciones
        organizaciones = angular.copy(organizaciones);
        third = Math.ceil((organizaciones.length + 1) / 3);
        $scope.organizacionesPart1 = organizaciones.splice(0, third);
        $scope.organizacionesPart2 = organizaciones.splice(0, third);
        $scope.organizacionesPart3 = organizaciones;

        // Obtiene las categorias UICN
        var categoriasUICN = _.filter(categorias, function(item){
          return !!item.uicn;
        });
        categoriasUICN = _.sortBy(categoriasUICN, function(item){
          var text = StringService.normalize(item.uicn);
          return text.toLowerCase();
        });
        categoriasUICN = _.groupBy(categoriasUICN, 'uicn');

        // Categorias
        var allCategories = [{kind: 'label', label: 'SINAP'}];
        angular.forEach(categorias, function(categoria){
          let categoriaId;
          if(categoria.id == 19) {
            // Local
            categoriaId = 20;
          }else if(categoria.id == 3){
            // Nacional
            categoriaId = [5, 6, 7, 8, 9, 10, 11, 2316, 2317].join(',');
          }else if(categoria.id == 12){
            // Regional
            categoriaId = [13, 14, 15, 16, 17].join(',');
          }else if(categoria.id == 4){
            // Sistema Parques Nacional Natural
            categoriaId = [5, 6, 7, 8, 9, 2316, 2317].join(',');
          }else{
            categoriaId = categoria.id;
          }
          allCategories.push({
            kind: 'obj',
            id: categoriaId,
            class: 'SINAP',
            label: categoria.termino
          });
        });
        allCategories.push({kind: 'label', label: 'UICN'});
        angular.forEach(categoriasUICN, function(categories, index){
          allCategories.push({
            kind: 'obj',
            id: _.map(categories, 'id').join(','),
            class: 'UICN',
            label: index
          });
        });

        third = Math.ceil((allCategories.length + 1) / 3);
        $scope.categoriasPart1 = allCategories.splice(0, third);
        $scope.categoriasPart2 = allCategories.splice(0, third);
        $scope.categoriasPart3 = allCategories;

        var temp = angular.copy(departamentos);
        third = Math.ceil((temp.length + 1) / 3);
        $scope.departamentosPart1 = temp.splice(0, third);
        $scope.departamentosPart2 = temp.splice(0, third);
        $scope.departamentosPart3 = temp;

        // @TODO: pasar a directiva
        var $search = $('input.search');
        $search.select2({
          placeholder: 'búsqueda por área protegida',
          minimumInputLength: 3,
          ajax: {
            quietMillis: 250,
            url: _.sprintf('%s/areas_protegidas/busqueda', backend),
            dataType: 'json',
            cache: true,
            data: function(term, page){
              return {
                //pagina: 1,
                //limite: 30,
                //orden: 'nombreNuevo',
                terminos: term,
                //modo: 'minimo'
              };
            },
            results: function(data, page){
              var items = _.map(data.items, function(item){
                return {id: item.id, text: _.sprintf('%s (Actos administrativos: %s)', item.nombre, item.num_resolucion)};
              });
              return {results: items};
            }
          }
        });
        $search.on('change', function(event){
          $search.select2('val', '');
          $state.go('main.views.area.views', {areaId: parseInt(event.val, 10)});
        });
      }
    ]);
}(window.angular, Math));

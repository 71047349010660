(function(angular){
  'use strict';

  var switchableFlag;

  var getMainStateName = function(state){
    var stateName, pos = state.name.indexOf('.');
    if(pos === -1){
      stateName = state.name;
    }else{
      stateName = state.name.substring(0, pos);
    }
    return stateName;
  };

  var handleChangeStates = function(states, $rootScope, $body, $spinner, $content){
    if(!states.angularLoaded){
      states.angularLoaded = true;
      // Quita la clase "page-on-load" inicial del body
      $body.removeClass('page-on-load');
      // Oculta el spinner
      $spinner.addClass('hide');
      // Muestra el contenido
      $content.removeClass('hide');
    }else{
      if(states.layoutChanged){
        states.layoutChanged = false;
        // Hace que coga la clase definida en el "data" de los estados
        // y oculta el layout "loading"
        $rootScope.bodyClass = false;
        // Oculta el spinner
        $spinner.addClass('hide');
        // Muestra el contenido
        $content.removeClass('hide');
      }else{
        // Oculta el mensaje "cargando..."
        $rootScope.stateLoading = false;
        switchableFlag = false;
      }
    }
  };

  angular.module('app.directives', [])

    .directive('siteLoader', ['$rootScope', 'scrollHelper',
      function($rootScope, scrollHelper){
        return {
          restrict: 'A',

          link: function(scope, element, attrs){
            // Si hay cambio de layout muestra el layout "loading" durante la
            // transicion de los estados

            var $body = angular.element('body');
            var $content = $body.find('> .' + attrs.contentContainer);
            var states = {
              angularLoaded: false,
              layoutChanged: false
            };
            $rootScope.bodyClass = false;
            $rootScope.stateLoading = false;

            $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
              // No hace nada en el primer "$stateChangeStart" que entra
              if(states.angularLoaded){
                // Verifica si hay cambio de layout
                var toLayout = getMainStateName(toState);
                var fromLayout = getMainStateName(fromState);
                if(toLayout !== fromLayout){
                  // Hubo cambio de layout
                  states.layoutChanged = true;
                  // Muestra el layout "loading"
                  $rootScope.bodyClass = 'page-on-load';
                  // Oculta el contenido
                  $content.addClass('hide');
                  // Muestra el spinner
                  element.removeClass('hide');
                }else{
                  // No hubo cambio de layout
                  if(angular.isUndefined(toParams.topScroll) || toParams.topScroll === true){
                    // Hace scroll hacia arriba y muestra el mensaje "cargando..."
                    switchableFlag = true;
                    scrollHelper.top().then(function(){
                      if(switchableFlag){
                        $rootScope.stateLoading = true;
                      }
                    });
                  }else{
                    // Solamente muestra el mensaje "cargando..."
                    debug.info('stateLoading true');
                    $rootScope.stateLoading = true;
                  }
                }
              }
            });

            $rootScope.$on('$stateChangeSuccess', function(){
              handleChangeStates(states, $rootScope, $body, element, $content);
            });

            $rootScope.$on('$stateNotFound', function(){
              handleChangeStates(states, $rootScope, $body, element, $content);
            });

            $rootScope.$on('$stateChangeError', function(){
              handleChangeStates(states, $rootScope, $body, element, $content);
            });
          }
        };
      }
    ])

    .directive('onLastRepeat', ['$timeout', function($timeout){
      return {
        restrict: 'A',
        scope: {
          onLastRepeat: '&'
        },

        link: function(scope, element, attrs){
          if(scope.$parent.$last){
            $timeout(function(){
              scope.onLastRepeat();
            });
          }
        }
      }
    }]);
}(window.angular));
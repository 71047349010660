(function(angular){
  'use strict';

  angular.module('paginator.services', [])

    .factory('PaginatorList', function(){
      var PaginatorList = function(pageSize){
        this.status = 'initialized';  // initialized, working, empty, ok, error
        this.pageSize = pageSize ? pageSize : null;
        this.reset();
      };

      PaginatorList.prototype.reset = function(){
        this.models = [];
        this.index = {};
        this.numberItems = 0;
      };

      PaginatorList.prototype.concatModels = function(models){
        angular.forEach(models, function(model){
          this.pushModel(model);
        }, this);

        if(this.numberItems === 0){
          this.status = 'empty';
        }
      };

      PaginatorList.prototype.unshiftModel = function(model){
        // Verifica que el modelo no exista previamente
        if(!this.index[model.key]){
          if(this.numberItems === 0){
            this.status = 'ok';
          }
          // Agrega el modelo a la lista
          this.models.unshift(model);
          // Actualiza el indice
          this.index[model.key] = true;
          // Actualiza el numero de modelos
          this.numberItems += 1;
        }
      };

      PaginatorList.prototype.pushModel = function(model){
        // Verifica que el modelo no exista previamente
        if(!this.index[model.key]){
          if(this.numberItems === 0){
            this.status = 'ok';
          }
          // Agrega el modelo a la lista
          this.models.push(model);
          // Actualiza el indice
          this.index[model.key] = true;
          // Actualiza el numero de modelos
          this.numberItems += 1;
        }
      };

      PaginatorList.prototype.updateModel = function(model){
        var pos = _.findIndex(this.models, {key: model.key});
        if(pos === -1){
          return false;
        }
        this.models[pos] = model;
        return true;
      };

      PaginatorList.prototype.deleteModel = function(modelKey){
        var pos = _.findIndex(this.models, {key: modelKey});
        if(pos === -1){
          return false;
        }

        // Borra el modelo de la lista
        this.models.splice(pos, 1);
        // Actualiza el indice
        delete this.index[modelKey];
        // Actualiza el numero de modelos
        this.numberItems -= 1;

        if(this.numberItems === 0){
          // Muestra que no hay registros
          this.status = 'empty';
        }else if(this.numberItems < parseInt(this.pageSize / 2, 10)){
          // Hay muy pocos modelos en la pagina actual
          return 'load_more';
        }
        return true;
      };

      return PaginatorList;
    })

    .factory('PaginatorButton', function(){
      var PaginatorButton = function(){
        this.reset();
      };

      PaginatorButton.prototype.reset = function(){
        this.status = 'no_more';  // ok, working, no_more
      };

      return PaginatorButton;
    })

    .factory('Paginator', ['ServerRequest', 'PaginatorList', 'PaginatorButton',
      function(ServerRequest, PaginatorList, PaginatorButton){
        var Paginator = function(fetchFunction, pageSize){
          this.fetchFunction = fetchFunction;
          this.serverRequest = new ServerRequest();
          this.list = new PaginatorList(pageSize);
          this.button = new PaginatorButton();
          this.successSubs = [];
        };

        Paginator.prototype.onResultsUpdated = function(callback){
          if(!angular.isFunction(callback)){
            throw('callback must be a function');
          }
          this.successSubs.push(callback);
        };

        Paginator.prototype.reset = function(){
          this.lastArgs = [];
          this.nextPageToken = null;
          this.serverRequest.reset();
          this.list.reset();
        };

        Paginator.prototype.query = function(){
          // Resetea el paginador
          this.reset();

          // Guarda una copia de los ultimos argumentos recibidos
          this.lastArgs = [];
          angular.forEach(arguments, function(arg){
            this.lastArgs.push(angular.copy(arg));
          }, this);

          // Hace la consulta
          this._doQuery();
        };

        Paginator.prototype.nextPage = function(){
          if(this.nextPageToken){
            // Agregar el cursor
            angular.extend(this.lastArgs[this.lastArgs.length - 1], {cursor: this.nextPageToken});
            debug.info('nextPage entro', this.lastArgs);

            // Hace la consulta
            this._doQuery();
          }
        };

        Paginator.prototype._doQuery = function(){
          if(this.lastArgs.length === 0){
            this.lastArgs = [{perpage: this.list.pageSize}];
          }else{
            if(!angular.isObject(this.lastArgs[this.lastArgs.length - 1])){
              this.lastArgs.push({});
            }
            angular.extend(this.lastArgs[this.lastArgs.length - 1], {perpage: this.list.pageSize});
          }

          if(this.list.numberItems === 0){
            this.list.status = 'working';
          }
          this.button.status = 'working';

          debug.debug('query args', angular.copy(this.lastArgs));
          this.serverRequest.start();
          var self = this;
          this.fetchFunction.apply(undefined, this.lastArgs)
            .then(function(data){
              self.serverRequest.success();
              self.setData(data);
            }, function(response){
              self.list.status = 'error';
              self.serverRequest.setErrorResponse(response);
            });
        };

        Paginator.prototype.setData = function(data){
          this.nextPageToken = data.more ? data.cursor : null;
          var models = angular.isArray(data.collection) ? data.collection : [];
          this.list.concatModels(models);

          if(this.list.status === 'ok' && this.nextPageToken){
            this.button.status = 'ok';
          }else{
            this.button.status = 'no_more';
          }

          angular.forEach(this.successSubs, function(callback){
            callback(models);
          });
        };

        Paginator.prototype.unshiftModel = function(model){
          this.list.unshiftModel(model);
        };

        Paginator.prototype.pushModel = function(model){
          this.list.pushModel(model);
        };

        Paginator.prototype.updateModel = function(model){
          return this.list.updateModel(model);
        };

        Paginator.prototype.deleteModel = function(modelKey){
          var result = this.list.deleteModel(modelKey);
          if(result === 'load_more'){
            // Verifica si existen mas paginas
            if(this.nextPageToken){
              // Carga la siguiente pagina
              // @TODO: aveces, aqui esta trayendo los records que acabaron de borrar.
              // o no los que se acabaron de borrar sino de nuevo los que ya estaban.
              // Tocaria verificar que en la lista no queden registros duplicados.
              this.nextPage();
            }
          }
        };

        return Paginator;
      }
    ]);
}(window.angular));
